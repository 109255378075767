import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./components/Header";
import Footer from "./components/Footer";
import HeroHome from "./pages/hero-home";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Brands from "./components/Brands";
import FeaturePage from "./pages/FeaturesPage";
import PricingPage from "./pages/PricingPage";
import FAQ from "./pages/FAQ";
import TOS from "./pages/TOS";
import Privacy from "./pages/Privacy";
import SingleBlogPage from "./pages/Blog/Blog-datails/Page";
import BlogPage from "./pages/Blog/Page";
import Blog from "../src/components/blog";
import FreeProxy from "../src/pages/FreeProxy";
import axios from "axios";
import Contactfooter from "../src/components/contactfooter"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { processSettingsToValues } from "./utils/helper";

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AppWrapper />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export const SettingsContext = createContext();

const AppWrapper = () => {
  const [settings, setSettings] = useState(null);

  const fetchSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/landing/settings`);
      return res.data?.payload?.settings || {};
    } catch (error) {
      console.error("Settings fetch error:", error);
      return {};
    }
  };

  const { data } = useQuery({ 
    queryKey: ["settings"], 
    queryFn: fetchSettings,
    staleTime: 3600000 // 1 hour cache
  });

  useEffect(() => {
    if (data) {
      processSettingsToValues(data).then(setSettings);
    }
  }, [data]);

  return (
    <SettingsContext.Provider value={settings}>
      <Router>
        <div className="App">
          <Helmet>
            {/* Universal SEO Essentials */}
            {/* Google Analytics Script */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-GCS0DDHET1" />
  <script dangerouslySetInnerHTML={{
    __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-GCS0DDHET1');
    `
  }} />
            <html lang="en" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow,max-image-preview:large" />
            <link rel="canonical" href={process.env.REACT_APP_SITE_URL} />
            
            {/* Bing Specific */}
            <meta name="msvalidate.01" content="YOUR_BING_WEBMASTER_KEY" />
            <meta name="geo.position" content="latitude;longitude" />
            <meta name="geo.placename" content="City, Country" />
            <meta name="geo.region" content="Country-Code" />

            {/* Social/Universal */}
            <meta property="og:site_name" content="AlertProxies" />
            <meta name="twitter:creator" content="@AlertProxies" />
            <meta name="twitter:site" content="@AlertProxies" />
          </Helmet>

          <Header />

          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>AlertProxies: Scrape The Web With Ease | Get Your Free Proxy Trial Today</title>
                    <meta 
                      name="description" 
                      content="The best proxy service platform with 30M+ Residential IPs and Affordable solutions. Scrape data from anywhere with ease!" 
                    />
                    <meta name="keywords" content="premium proxies,secure VPN,private browsing,ip masking,web anonymity" />

                    {/* OpenGraph/Facebook */}
                    <meta property="og:title" content="AlertProxies - Affordable Proxy Service to Gather Data Effortlessly" />
                    <meta property="og:description" content="The best proxy service platform with 30M+ Residential IPs and Affordable solutions. Scrape data from anywhere with ease!" />
                    <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/`} />
                    <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}/favicon.ico`} />
                    <meta property="og:type" content="website" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="AlertProxies - Affordable Proxy Service to Gather Data Effortlessly" />
                    <meta name="twitter:description" content="The best proxy service platform with 30M+ Residential IPs and Affordable solutions. Scrape data from anywhere with ease!" />
                    <meta name="twitter:image" content={`${process.env.REACT_APP_SITE_URL}/favicon.ico`} />

                    {/* Bing/Microsoft Clarity */}
                    <meta name="msapplication-TileImage" content={`${process.env.REACT_APP_SITE_URL}/favicon.ico`} />

                    {/* Enhanced Schema.org */}
                    <script type="application/ld+json">
                      {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": ["WebSite", "Organization"],
                        "name": "AlertProxies",
                        "url": process.env.REACT_APP_SITE_URL,
                        "logo": `${process.env.REACT_APP_SITE_URL}/favicon.ico`,
                        "description": "The best proxy service platform with 30M+ Residential IPs and Affordable solutions. Scrape data from anywhere with ease!",
                        "foundingDate": "2020",
                        "founders": [{
                          "@type": "Person",
                          "name": "Daniel"
                        }]
                      })}
                    </script>
                    
                  </Helmet>

                  <HeroHome />
                  <Brands />
                  <Features />
                  <Pricing />
                  <Blog />
                  <Contactfooter/>
                </>
              }
            />

            {/* Other Routes */}
            {/* Add similar Helmet blocks to other route components */}
            <Route path="/feature" element={<FeaturePage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<SingleBlogPage />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/proxylist" element={<FreeProxy />} />
            <Route path="/tos" element={<TOS />} />
            <Route path="/Privacy" element={<Privacy />} />
          </Routes>

          <Footer />
        </div>
      </Router>
    </SettingsContext.Provider>
  );
};

export default App;