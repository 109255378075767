import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"; // Import the check icon from Heroicons v2
import { Link } from "react-router-dom";
import axios from "axios";

const PROXY_TYPES = {
  RESIDENTIAL: "RESIDENTIAL",
  DATACENTER: "DATACENTER",
  IPV6: "IPV6",
  MOBILE: "MOBILE",
  ISP: "ISP",
};

const Pricing = ({ up = false }) => {
  const [currentProviderName, setcurrentProviderName] = useState(PROXY_TYPES.RESIDENTIAL);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu

  const [plans, setPlans] = useState([]);

  const fetchPlans = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/landing/plans`);
      if (res.status === 200) {
        setPlans(res.data.payload.plans);
      } else {
        console.log("unable to fetch plans");
      }
    } catch (error) {
      console.log("Error fetching plans", error);
    }
  };

  const handleChatOpen = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };
  const dummyPlans = {
    residential: [
      {
        title: "2GB",
        bandwidth: 2,
        price: 6.5,
        features: [
          "30M+ Real Residential IPs",
          "IP & User:Pass Authentication",
          "Country, State, City & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/SOCKS5 Protocol Supported",
        ],
      },
      {
        title: "5GB",
        bandwidth: 5,
        price: 14,
        features: [
          "30M+ Real Residential IPs",
          "IP & User:Pass Authentication",
          "Country, State, City & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/SOCKS5 Protocol Supported",
        ],
      },
      {
        title: "10GB",
        bandwidth: 10,
        price: 25,
        features: [
          "30M+ Real Residential IPs",
          "IP & User:Pass Authentication",
          "Country, State, City & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/SOCKS5 Protocol Supported",
        ],
      },
      {
        title: "25GB",
        bandwidth: 25,
        price: 57.5,
        features: [
          "30M+ Real Residential IPs",
          "IP & User:Pass Authentication",
          "Country, State, City & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/SOCKS5 Protocol Supported",
        ],
      },
      {
        title: "50GB",
        bandwidth: 50,
        price: 105,
        features: [
          "30M+ Real Residential IPs",
          "IP & User:Pass Authentication",
          "Country, State, City & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/SOCKS5 Protocol Supported",
        ],
      },
    ],
    datacenter: [
      {
        title: "1 Day",
        bandwidth: 0,
        price: 8,
        features: [
          "35 000 IPs",
          "1 IP Authentication",
          "Unlimited Bandwidth",
          "Country Targeting",
          "Unlimited Connections",
          "HTTP/s Support",
        ],
      },
      {
        title: "7 Days",
        bandwidth: 0,
        price: 45,
        features: [
          "35 000 IPs",
          "1 IP Authentication",
          "Unlimited Bandwidth",
          "Country Targeting",
          "Unlimited Connections",
          "HTTP/s Support",
        ],
      },
      {
        title: "30 Days",
        bandwidth: 0,
        price: 120,
        features: [
          "35 000 IPs",
          "1 IP Authentication",
          "Unlimited Bandwidth",
          "Country Targeting",
          "Unlimited Connections",
          "HTTP/s Support",
        ],
      },
    ],
    ipv6: [
      {
        title: "1 Day - 30Mbps",
        bandwidth: 0,
        price: 8,
        features: [
          "/29 Network IP Pool size",
          "Unlimited Bandwidth",
          "IP & User:Pass Authentication",
          "Country Targeting",
          "HTTP/s Support",
        ],
      },
      {
        title: "7 Days - 30Mbps",
        bandwidth: 0,
        price: 40,
        features: [
          "/29 Network IP Pool size",
          "Unlimited Bandwidth",
          "IP & User:Pass Authentication",
          "Country Targeting",
          "HTTP/s Support",
        ],
      },
      {
        title: "30 Days - 30Mbps",
        bandwidth: 0,
        price: 100,
        features: [
          "/29 Network IP Pool size",
          "Unlimited Bandwidth",
          "IP & User:Pass Authentication",
          "Country Targeting",
          "HTTP/s Support",
        ],
      },
    ],
    mobile: [
      {
        title: "Mobile 1GB",
        bandwidth: 1,
        price: 4.5,
        features: [
          "550K+ Real Mobile IPs",
          "Unlimited Bandwidth",
          "User:Pass Authentication",
          "Country & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/s Support",
        ],
      },
      {
        title: "Mobile 5GB",
        bandwidth: 5,
        price: 20,
        features: [
          "550K+ Real Mobile IPs",
          "Unlimited Bandwidth",
          "User:Pass Authentication",
          "Country & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/s Support",
        ],
      },
      {
        title: "Mobile 25GB",
        bandwidth: 25,
        price: 85,
        features: [
          "550K+ Real Mobile IPs",
          "Unlimited Bandwidth",
          "User:Pass Authentication",
          "Country & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/s Support",
        ],
      },
      {
        title: "Mobile 50GB",
        bandwidth: 50,
        price: 150,
        features: [
          "550K+ Real Mobile IPs",
          "Unlimited Bandwidth",
          "User:Pass Authentication",
          "Country & ISP Targeting",
          "Rotating & Sticky Sessions",
          "Unlimited Concurrent Connections",
          "HTTP/s Support",
        ],
      },
    ],
    ISP: [
      {
        title: "1 Static ISP Static Proxy",
        bandwidth: 0,
        price: 4,
        features: [
          "Worldwide ISP Locations",
          "Unlimited Bandwidth",
          "Tier 1 & 2 ISP's",
          "Country Targeting",
          "Static ISP Proxies",
          "Unlimited Concurrent Connections",
          "HTTP/s & SOCKS5 Support",
        ],
      },
      {
        title: "10 Static ISP Static Proxies",
        bandwidth: 0,
        price: 30,
        features: [
          "Worldwide ISP Locations",
          "Unlimited Bandwidth",
          "Tier 1 & 2 ISP's",
          "Country Targeting",
          "Static ISP Proxies",
          "Unlimited Concurrent Connections",
          "HTTP/s & SOCKS5 Support",
        ],
      },
      {
        title: "25 Static ISP Static Proxies",
        bandwidth: 0,
        price: 62.5,
        features: [
          "Worldwide ISP Locations",
          "Unlimited Bandwidth",
          "Tier 1 & 2 ISP's",
          "Country Targeting",
          "Static ISP Proxies",
          "Unlimited Concurrent Connections",
          "HTTP/s & SOCKS5 Support",
        ],
      },
      {
        title: "50 Static ISP Static Proxies",
        bandwidth: 0,
        price: 125,
        features: [
          "Worldwide ISP Locations",
          "Unlimited Bandwidth",
          "Tier 1 & 2 ISP's",
          "Country Targeting",
          "Static ISP Proxies",
          "Unlimited Concurrent Connections",
          "HTTP/s & SOCKS5 Support",
        ],
      },
      {
        title: "100 Static ISP Static Proxies",
        bandwidth: 0,
        price: 230,
        features: [
          "Worldwide ISP Locations",
          "Unlimited Bandwidth",
          "Tier 1 & 2 ISP's",
          "Country Targeting",
          "Static ISP Proxies",
          "Unlimited Concurrent Connections",
          "HTTP/s & SOCKS5 Support",
        ],
      },
    ],
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <section className={up ? "py-32" : "bg-gray-100 "}>
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h6 className="text-purple-600 font-semibold tracking-widest">PRICING</h6>
          <p className="text-3xl text-gray-900 font-inter font-semibold">Affordable, Yet So High Quality</p>
        </div>

        <div className="relative">
          <div className="flex justify-center mb-8">
            <ul className="hidden md:flex p-2 space-x-3 rounded-full border-2 border-purple-200 bg-white px-6 py-2 transition duration-200">
              <li className="nav-item">
                <button
                  className={`px-4 py-2 rounded-full ${
                    currentProviderName === PROXY_TYPES.RESIDENTIAL
                      ? "bg-purple-500 text-white"
                      : "text-gray-500 hover:text-gray-700 transition duration-300"
                  }`}
                  onClick={() => setcurrentProviderName(PROXY_TYPES.RESIDENTIAL)}
                >
                  Residential
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`px-4 py-2 rounded-full ${
                    currentProviderName === PROXY_TYPES.DATACENTER
                      ? "bg-purple-500 text-white"
                      : "text-gray-500 hover:text-gray-700 transition duration-300"
                  }`}
                  onClick={() => setcurrentProviderName(PROXY_TYPES.DATACENTER)}
                >
                  Datacenter
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`px-4 py-2 rounded-full ${
                    currentProviderName === PROXY_TYPES.IPV6
                      ? "bg-purple-500 text-white"
                      : "text-gray-500 hover:text-gray-700 transition duration-300"
                  }`}
                  onClick={() => setcurrentProviderName(PROXY_TYPES.IPV6)}
                >
                  IPv6
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`px-4 py-2 rounded-full ${
                    currentProviderName === PROXY_TYPES.MOBILE
                      ? "bg-purple-500 text-white"
                      : "text-gray-500 hover:text-gray-700 transition duration-300"
                  }`}
                  onClick={() => setcurrentProviderName(PROXY_TYPES.MOBILE)}
                >
                  Mobile
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`px-4 py-2 rounded-full ${
                    currentProviderName === PROXY_TYPES.ISP
                      ? "bg-purple-500 text-white"
                      : "text-gray-500 hover:text-gray-700 transition duration-300"
                  }`}
                  onClick={() => setcurrentProviderName(PROXY_TYPES.ISP)}
                >
                  Static ISP
                </button>
              </li>
            </ul>

            {/* Mobile menu button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 bg-white rounded-xl  shadow-lg hover:bg-purple-100 border border-purple-300 transition duration-300"
              aria-label="Toggle Menu"
            >
              <span className="mr-2">Choose Proxy Type</span>
              <svg
                className="w-6 h-6 inline-block"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden absolute top-16 left-0 w-full bg-white border-t border-gray-200 shadow-lg">
              <ul className="flex flex-col p-2 space-y-1">
                <li>
                  <button
                    className={`block px-4 py-2 w-full text-left ${
                      currentProviderName === PROXY_TYPES.RESIDENTIAL
                        ? "bg-purple-500 text-white"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => setcurrentProviderName(PROXY_TYPES.RESIDENTIAL)}
                  >
                    Residential
                  </button>
                </li>
                <li>
                  <button
                    className={`block px-4 py-2 w-full text-left ${
                      currentProviderName === PROXY_TYPES.DATACENTER ? "bg-purple-500 text-white" : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => setcurrentProviderName(PROXY_TYPES.DATACENTER)}
                  >
                    Datacenter
                  </button>
                </li>
                <li>
                  <button
                    className={`block px-4 py-2 w-full text-left ${
                      currentProviderName === PROXY_TYPES.IPV6 ? "bg-purple-500 text-white" : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => setcurrentProviderName(PROXY_TYPES.IPV6)}
                  >
                    IPv6
                  </button>
                </li>
                <li>
                  <button
                    className={`block px-4 py-2 w-full text-left ${
                      currentProviderName === PROXY_TYPES.MOBILE ? "bg-purple-500 text-white" : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => setcurrentProviderName(PROXY_TYPES.MOBILE)}
                  >
                    Mobile
                  </button>
                </li>
                <li>
                  <button
                    className={`block px-4 py-2 w-full text-left ${
                      currentProviderName === PROXY_TYPES.ISP ? "bg-purple-500 text-white" : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => setcurrentProviderName(PROXY_TYPES.ISP)}
                  >
                    Static ISP
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-3">
          {plans
            ?.filter((plan) => plan.proxyType === currentProviderName)
            .map((singlePlan) => (
              <div className="bg-white rounded-xl p-6 shadow-lg hover:bg-purple-100 transition duration-300" key={singlePlan.title}>
                <div className="mb-4">
                  <h6 className="text-lg text-gray-900 font-semibold mb-2 font-inter">{singlePlan.name}</h6>
                  <div className="flex justify-between items-center">
                    <p className="bg-white border border-purple-500 text-gray-900 py-1 px-3 rounded-lg">
                      {singlePlan.bandwidth <= 0 ? "Unlimited" : `${singlePlan.bandwidth} GB`} Traffic
                    </p>
                    <div className="text-right">
                      <h6 className="text-purple-600 text-xl">${singlePlan.amount}</h6>
                    </div>
                  </div>
                </div>
                <hr className="border-gray-300" />
                <div className="mt-4 mb-4">
                  <p className="text-gray-900 mb-3 font-inter font-semibold">Plan Includes:</p>
                  <ul className="space-y-2 text-gray-700">
                    {(() => {
                      const features = JSON.parse(singlePlan.description);

                      return features.map((feature, index) => {
                        return (
                          <li key={index} className="flex items-center">
                            <CheckIcon className="w-5 h-5 text-purple-600 mr-2" />
                            {feature.point}
                          </li>
                        );
                      });
                    })()}
                  </ul>
                </div>
                <hr className="border-gray-300" />
                <div className="flex justify-center mt-4">
                  <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}/auth/register`}
                    className="rounded-full border-2 border-purple-200 bg-white px-6 py-2 transition duration-500 ease-in-out hover:bg-pink-100 hover:text-gray-800"
                  >
                    Purchase Now
                  </a>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-center mt-2">
          <section className="text-center mt-8">
            <button
              onClick={handleChatOpen}
              className="rounded-full border-2 border-purple-200 bg-white px-6 py-2 transition duration-500 ease-in-out hover:bg-pink-100 hover:text-gray-800 mb-6"
            >
              Claim Your Free Trial
            </button>
          </section>
        </div>
      </div>
      <div className="">
        <hr className="border-gray-300" />
      </div>
    </section>
  );
};

export default Pricing;
