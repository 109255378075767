import { useContext } from "react";
import Logo from "../assets/logo.png";

import { SettingsContext } from "../App";
export default function Footer({ border = false }) {
  const settings = useContext(SettingsContext);
  return (
    <footer className={`bg-white text-gray-800 ${border ? "border-t border-gray-200" : ""}`}>
      <div className="container mx-auto px-6 pt-6">
        {/* Top area: Blocks */}
        <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-4 items-start">
          {/* 1st block */}
          <div className="flex items-center space-y-4 sm:col-span-2 md:col-span-1">
            <img src={Logo} alt="Logo" className="w-32 h-auto" />
          </div>

          {/* 2nd block */}
          <div className="space-y-4 sm:col-span-2 md:col-span-1">
            <h3 className="text-lg font-semibold text-black">Product</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Pricing">
                  Residential Proxies
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Pricing">
                  Datacenter Proxies
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Pricing">
                  Mobile Proxies
                </a>
              </li>
              <li>
                <li>
                  <a className="text-gray-700 hover:text-black transition duration-200" href="/Pricing">
                    IPV6 Proxies
                  </a>
                </li>
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="space-y-4 sm:col-span-2 md:col-span-1">
            <h3 className="text-lg font-semibold text-black">Company</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Feature">
                  Features
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Pricing">
                  Pricing & Plans
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Blog">
                  Blog
                </a>
              </li>

              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/Proxylist">
                  Free Proxylist
                </a>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          <div className="space-y-4 sm:col-span-2 md:col-span-1">
            <h3 className="text-lg font-semibold text-black">Resources</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="https://discord.gg/FZmu8N3Vgq">
                  Community
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="/tos">
                  Terms of service
                </a>
              </li>
              <li>
                <a className="text-gray-700 hover:text-black transition duration-200" href="https://t.me/Cpztele">
                  Report a vulnerability
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom area: Links */}
        <div className="flex flex-col-reverse gap-6 py-6 sm:flex-row sm:items-center sm:justify-between">
          <p className="text-sm text-gray-600">{settings?.Copyright}</p>
          <ul className="flex gap-6 text-sm">
            <li>
              <a className="text-gray-700 hover:text-black transition duration-200" href="/privacy">
                Privacy
              </a>
            </li>
            <li>
              <a className="text-gray-700 hover:text-black transition duration-200" href="/tos">
                Terms
              </a>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
