import React, { useContext } from "react";
import Avatar01 from "../assets/avatar-01.jpg";
import Avatar02 from "../assets/avatar-02.jpg";
import Avatar03 from "../assets/avatar-03.jpg";
import Avatar04 from "../assets/avatar-04.jpg";
import Avatar05 from "../assets/avatar-05.jpg";
import Avatar06 from "../assets/avatar-06.jpg";
import Trustpilot from "../assets/Image_5-removebg-preview.png";
import { SettingsContext } from "../App";

export default function HeroHome() {
  const settings = useContext(SettingsContext);

  return (
    <section className="relative bg-gray-100">
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        {/* Hero content */}
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          {/* Section header */}
          <div className="pb-12 text-center md:pb-16">
            {/* Trustpilot image centered */}
            <div className="flex justify-center mb-0">
              <img src={Trustpilot} alt="Trustpilot" className="w-auto max-h-16" />
            </div>

            <h1
              className="mb-6 border-y text-5xl font-bold border-image-[linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] md:text-6xl"
              data-aos="zoom-y-out"
              data-aos-delay={150}
            >
              Premium Proxy Service Made <br className="max-lg:hidden" />
              For Web Scraping
            </h1>

            {/* Moved Loved by Developers and Avatar section here */}
            <div className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
              {/* Text */}
              <p className="text-sm font-medium text-gray-800 sm:order-1">Loved By Developers Worldwide</p>

              {/* Avatars */}
              <div className="-mx-0.5 flex justify-center -space-x-3 sm:order-2">
                {[Avatar01, Avatar02, Avatar03, Avatar04, Avatar05, Avatar06].map((avatar, index) => (
                  <img
                    key={index}
                    className="box-content rounded-full border-2 border-gray-50"
                    src={avatar}
                    width={32}
                    height={32}
                    alt={`Avatar ${index + 1}`}
                  />
                ))}
              </div>
            </div>

            <div className="mx-auto mt-8">
              <p className="mb-8 text-lg text-gray-700" data-aos="zoom-y-out" data-aos-delay={300}>
                The primary goal of our service is to provide proxies with regularly updated and fresh IP addresses for those who need
                them.
              </p>
              {/* CTA Buttons */}
              <div className="flex flex-col items-center gap-4 sm:flex-row sm:justify-center">
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/auth/register`}
                  className="inline-block px-6 py-2 text-base font-sans text-white bg-purple-400 rounded-lg shadow hover:bg-black transition duration-500"
                >
                  Start Free Trial{" "}
                  <span className="ml-1 tracking-normal text-white transition-transform group-hover:translate-x-0.5">-&gt;</span>
                </a>
                <a
                  href="#FAQ"
                  className="inline-block px-6 py-2 text-base font-sans text-black rounded-lg shadow hover:bg-black hover:text-white transition duration-500"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div className="mx-auto max-w-2xl p-8 shadow-xl rounded-lg animation-fade-up">
            <pre className="font-mono text-gray-700 bg-gray-100 p-4 rounded-lg overflow-x-auto animation-scale-up">
              <span className="text-purple-500">import</span> <span className="text-purple-700">requests</span>
              <br />
              <br />
              <span className="text-purple-500">def</span> <span className="text-purple-700">setup_proxy</span>
              <span className="text-gray-600">():</span>
              <br />
              <span className="text-gray-700">
                {" "}
                proxy_url = <span className="text-purple-600">"https://alertproxies.com:00000"</span>
              </span>
              <br />
              <span className="text-gray-700">
                {" "}
                target_url = <span className="text-purple-600">"https://alertproxies.com/api"</span>
              </span>
              <br />
              <br />
              <span className="text-purple-500"> session = requests.Session()</span>
              <br />
              <span className="text-purple-500"> session.proxies =</span>
              <br />
              <span className="text-gray-700"> &#123;</span>
              <br />
              <span className="text-gray-600"> "http": proxy_url,</span>
              <br />
              <span className="text-gray-600"> "https": proxy_url,</span>
              <br />
              <span className="text-gray-700"> &#125;</span>
              <br />
              <br />
              <span className="text-purple-500"> try:</span>
              <br />
              <span className="text-gray-700"> response = session.get(target_url)</span>
              <br />
              <span className="text-gray-700">
                {" "}
                print(<span className="text-purple-600">"Response from target URL:"</span>)
              </span>
              <br />
              <span className="text-gray-700"> print(response.text)</span>
              <br />
              <span className="text-purple-500"> except requests.RequestException as error:</span>
              <br />
              <span className="text-gray-700">
                {" "}
                print(<span className="text-purple-600">"An error occurred while making the request"</span>)
              </span>
              <br />
              <br />
              <span className="text-purple-500">setup_proxy()</span>
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
}
