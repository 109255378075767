import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogItem from "../components/Blog/Blogitem";
import SectionHeader from "../components/SectionHeader";
import Metadata from "../components/MetaData"; // Adjust path as needed

const BlogPage = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(id ? `${process.env.REACT_APP_BLOG_BACKEND_URL}/blog/${id}` : `${process.env.REACT_APP_BLOG_BACKEND_URL}/blog`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Fetched blog data:", data);
        setBlogs(Array.isArray(data) ? data : [data]);
      } catch (err) {
        console.error("Failed to fetch blogs:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [id]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(blogs.length / postsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const metadata = {
    title: "AlertProxies Blog - Check Out Latest News On Proxies",  };

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-center text-red-600">Error: {error}</p>;

  return (
    <>
      <section className="py-16 lg:py-24 xl:py-12 -mt-4 bg-gray-100">
        <div className="container mx-auto px-4 md:px-8 xl:px-12">
          <h2 className="mx-auto mb-4 text-3xl font-semibold text-black  md:w-4/5 xl:w-1/2 xl:text-sectiontitle3 text-center">
            Read AlertProxies Blogs
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-12">
            {currentPosts.length > 0 ? (
              currentPosts.map((post) => <BlogItem key={post.id} blog={post} />)
            ) : (
              <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-600">
                <p>No blog posts available.</p>
              </div>
            )}
          </div>
          {totalPages > 1 && (
            <div className="mt-8 flex justify-center">
              <ul className="flex space-x-4">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index + 1}>
                    <button
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-4 py-2 rounded-lg ${
                        currentPage === index + 1 ? "bg-purple-500 text-white" : "bg-gray-300 text-black"
                      } hover:bg-purple-600 transition duration-300`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default BlogPage;
