import { FaTelegram, FaEnvelope, FaHeadset } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';

const ProxyHero = () => {
  return (
    <div className="py-16 flex items-center justify-center bg-gray-50 p-6">
      <div className="max-w-6xl w-full mx-auto text-center">
        {/* Top Heading */}
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-white mb-8 md:mb-12 font-sans">
          Get Updates About <span className="text-purple-500">AlertProxies</span>
        </h2>

        {/* Social Links Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 max-w-xl mx-auto">
          {/* Telegram */}
          <SocialLink 
            icon={<FaTelegram className="w-5 h-5" />} 
            text="Telegram" 
            href="https://t.me/alertproxies" 
          />
          
          {/* Email */}
          <SocialLink 
            icon={<FaEnvelope className="w-5 h-5" />} 
            text="Partner With Us" 
            href="mailto:marketing@alertproxies.com" 
          />

          {/* Expanded Live Support with Crisp Chat */}
          <div
            className="group bg-white p-6 md:p-7 rounded-xl flex items-center gap-3 md:gap-4 hover:bg-purple-50
            border border-gray-200 hover:border-purple-200
            h-full hover:shadow-md transition duration-200 col-span-2 cursor-pointer"
            onClick={handleCrispChat}
          >
            <div className="bg-purple-100 dark:bg-purple-900/20 p-2 rounded-lg text-purple-500 dark:text-purple-400">
              <FaHeadset className="w-6 h-6" />
            </div>
            <span className="text-sm md:text-base font-medium text-gray-700 dark:text-gray-200 flex-grow">
              Live Support
            </span>
            <FiArrowRight className="text-gray-400 group-hover:text-purple-500 transition-colors" />
          </div>
        </div>
      </div>
    </div>
  );
};

const handleCrispChat = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    } else {
      console.warn("Crisp chat is not initialized yet.");
    }
  };
  

// Social Link Component
const SocialLink = ({ icon, text, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="group bg-white p-4 md:p-5 rounded-xl flex items-center gap-3 md:gap-4 hover:bg-purple-50
    border border-gray-200 hover:border-purple-200
    h-full hover:shadow-md transition duration-200"
  >
    <div className="bg-purple-100 dark:bg-purple-900/20 p-2 rounded-lg text-black">
      {icon}
    </div>
    <span className="text-sm md:text-base font-medium text-gray-700 dark:text-gray-200 flex-grow">
      {text}
    </span>
    <FiArrowRight className="text-gray-400 group-hover:text-purple-500 transition-colors" />
  </a> 
);

export default ProxyHero;
